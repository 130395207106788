let router_names: any[] = []

// 绑定历史事件，页面初始化就弹出，需要加上setTimeout
export function bind_popstate(name: string, fn:() => void) {
  router_names.push(name)
  window.history.pushState(null, '')
  window.addEventListener(name, fn, false)
}

// 绑定历史事件
export function unbind_popstate(name: string, fn:() => void) {
  router_names = router_names.filter(item => item !== name)
  window.removeEventListener(name, fn, false)
}

// 监听事件
window.addEventListener('popstate', () => {
  const name = router_names.pop()
  const ev = new Event(name, {'bubbles':true, 'cancelable':true})
  window.dispatchEvent(ev)
}, false)
