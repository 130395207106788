import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { removeSession, getSession } from './utils/session'
import { logout } from './pages/login/service'
import { noLoginArr } from './pages/login/config'

// 登录
const Login = () => import('./pages/login/index.vue')

//评测记录
const HistoryList = () =>import('./pages/history-list/index.vue')
//详情
const Detail = () =>import('./pages/detail/index.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/',
    name: '首页',
    redirect: '/detail',
  },
  {
    path: '/history-list',
    name: '评测记录',
    component: HistoryList
  },
  {
    path: '/detail',
    name: '评测详情',
    component: Detail
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 地址是否被包含
export const path_includes = (path:string, verify_list:any[]) => {
  if (path === '/login') return true
  for (let key of verify_list) {
    if (key.includes('*')) {
      key = key.replace('*', '.*')
    }
    const reg = new RegExp(`^${key}$`)
    if (reg.test(path)) {
      return true
    }
  }
  return false
}

router.beforeEach(async (to, from, next) => {
  const {path, fullPath, query} = to
  const redirect = encodeURIComponent(fullPath)

  const authToken = getSession()

  // url直接传token登录
  const token = query.token
  if (path !== '/login' && token && !authToken) {
    router.push({ path: '/login', query: {redirect, token}})
    return
  }

  // 不需要登录验证的地址
  for (let i = 0, len = noLoginArr.length; i < len; i++) {
    if (noLoginArr[i] === path) {
      return next()
    }
  }

  const verifyArr = (authToken && authToken.verifyArr) || []
  // 权限验证
  if (!path_includes(path, verifyArr)) return next({ path: '/login', query: {redirect} })

  if (path === '/login' && authToken) {
    try {
      await logout()
    } finally {
      removeSession()
      setTimeout(()=>{
        location.reload()
      }, 300)
    }
  }
  next()
})

export default router
