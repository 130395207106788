import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, _mergeProps({
    show: _ctx.visible,
    onClickOverlay: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["show"]))
}