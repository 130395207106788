import { createApp } from 'vue'
import vant from 'vant'
import 'vant/lib/index.css'
import './reset.css'
import App from './App.vue'
import router from './router'
import store from './store'

import Loading from './components/Loading.vue'
import Popup from './components/Popup.vue'

createApp(App)
  .use(vant)
  .use(store)
  .use(router)
  .component(Loading.name, Loading)
  .component(Popup.name, Popup)
  .mount('#app')
