// 不需要登录验证的地址
export const noLoginArr = [
]

// 不需要权限验证的地址
export const noVerifyArr = [
  ...noLoginArr,
  '/',
  '/history-list',
  '/detail',
]

// 有权限验证的地址
export const verifyArr = [
  ...noVerifyArr
]
