
import { defineComponent, onUnmounted } from 'vue';
import { bind_popstate, unbind_popstate } from "../utils/popstate"
import {create_guid} from "../utils/format"

export default defineComponent({
  name: 'GPopup',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  setup (_, {emit}) {
    const listen_name = create_guid()

    const popstate = () => {
      emit('update:visible', false)
    }
    // 历史回退
    bind_popstate(listen_name, popstate)

    onUnmounted(() => {
      unbind_popstate(listen_name, popstate)
    })

  }
})
