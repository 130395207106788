import CryptoJS from 'crypto-js'

const secret_key = CryptoJS.enc.Utf8.parse(GLOBAL_CONFIG.secret_key)

// 加密
export function encrypt (data: unknown, debug=GLOBAL_CONFIG.debug) {
  if (debug) return data
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secret_key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  return encrypted.toString()
}

// 解密
export function decrypt (data: string | CryptoJS.lib.CipherParams, debug=GLOBAL_CONFIG.debug) {
  if (debug) return data
  const bytes = CryptoJS.AES.decrypt(data, secret_key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch (error) {
    return bytes.toString(CryptoJS.enc.Utf8)
  }
}
