import api from '../../utils/api'

// 登录
export async function login (payload:object) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         token: 'sadwdadasdsadsadas',
  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/prostate/session`, payload)
}

// 退出
export async function logout () {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: null
      })
    }, 500)
  })
  // return api.delete('/api/v1/user/login')
}

// 获取验证码
export async function send_code (payload:object) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/prostate/session/code',{params: payload})
}
